import React, { Fragment } from "react"

import Sub from "~layout/sub"
import aside from "~content/products/aside"

import { HeadingIcon } from "~styled"

const crumbs = [
  { text: "Products", to: "/products" },
  { text: "Qoqolo", to: "/products/qoqolo/" },
]

const QoqoloPage = () => {
  let content = (
    <Fragment>
      <HeadingIcon>
        <img src={require("~images/products/qoqolo.png").default} alt="Qoqolo" title="Qoqolo" />
      </HeadingIcon>
      <h1>Smart solutions for your school.</h1>
      <h4>Revolutionize your School Operations with Qoqolo School Management System</h4>
      <p>As your schools adapt to the new normal, Qoqolo provides a smart, intuitive, and comprehensive solution to streamline your school's operations. Our School Management System aims to simplify the workload of your teachers, staff, and administrators so that they can focus on what they do best - educating and nurturing students.</p>

      <h5>Student Management</h5>
    <p>
    Qoqolo School Management System provides an all-in-one platform to store, organize, and access student information anytime, anywhere. With modules such as Health Records and Attendance Reports, you can keep track of students' progress and ensure their safety.
    </p>
      <div className="productFeatures flex">
        <img src={require('~images/products/QQL/Features/1. Student Management Made Easy 1.jpg').default} alt="Student Management" className="margin-auto img-responsive" />
        <img src={require('~images/products/QQL/Features/1. Student Management Made Easy 2.jpg').default} alt="Student Management" className="margin-auto img-responsive" />
      </div>

      <h5>Parent Engagement at Your Fingertips</h5>
    <p>We understand the importance of parent-teacher communication, which is why we have built an intuitive, two-way platform for easy interaction. Share class activities and learning materials, push announcements, and schedule parent-teacher conferences effortlessly.</p>

    <div className="productFeatures flex-reverse">
        <img src={require('~images/products/QQL/Features/2. Parent Engagement at Your Fingertips 1.jpg').default} alt="Parent Engagement at Your Fingertips" className="margin-auto img-responsive" />
        <img src={require('~images/products/QQL/Features/2. Parent Engagement at Your Fingertips 2.png').default} alt="Parent Engagement at Your Fingertips" className="margin-auto img-responsive" />
      </div>

      <h5>Effortlessly Track Student Development</h5>
    <p>Capturing a child's learning journey is a vital part of early education. With Qoqolo's ePortfolio, you can easily customize individual student portfolios with popular tags, observation notes, and an auto-fill function, saving you time and effort. Generate progress reports with just a few clicks and track your students' development easily.
</p>

<div className="productFeatures flex">
        <img src={require('~images/products/QQL/Features/3. Effortlessly Track Student Development 1.png').default} alt="Effortlessly Track Student Development" className="margin-auto img-responsive" />
        <img src={require('~images/products/QQL/Features/3. Effortlessly track Student Development 2.jpg').default} alt="Effortlessly Track Student Development" className="margin-auto img-responsive" />
      </div>

      <h5>Streamline Centre Operations Management</h5>
    <p>Running a school can be a daunting task, but with Qoqolo's modules, you can manage day-to-day operations efficiently. From staff scheduling to planning for centre development, our system simplifies the process, saving you time and resources.
</p>
<div className="productFeatures flex-reverse">
        <img src={require('~images/products/QQL/Features/4. Streamlined Centre Operations Management 1.jpg').default} alt="Streamline Centre Operations Management" className="margin-auto img-responsive" />
        <img src={require('~images/products/QQL/Features/4. Streamlined Centre Operations Management 2.png').default} alt="Streamline Centre Operations Management" className="margin-auto img-responsive" />
      </div>

<h5>Efficient Staff Management</h5>
    <p>Our School Management System enables paperless management of all employee information, including staff rosters and leave applications. Conveniently schedule training for your staff and ensure that your school is fully staffed at all times.
</p>
<div className="productFeatures flex">
        <img src={require('~images/products/QQL/Features/5. Efficient Staff Management 1.png').default} alt="Efficient Staff Management" className="margin-auto img-responsive" />
        <img src={require('~images/products/QQL/Features/5. Efficient Staff Management 2.jpg').default} alt="Efficient Staff Management" className="margin-auto img-responsive" />
      </div>


<h5>Seamless Fee Management</h5>
    <p>Managing fee structures, assigning subsidies, and generating invoices and receipts has never been easier. With Qoqolo's fee management module, you can streamline your billing processes and focus on providing quality education to your students.
</p>
<div className="productFeatures flex-row">
        <img src={require('~images/products/QQL/Features/6. Seamless fee management 1.jpg').default} alt="Seamless Fee Management" className="margin-auto img-responsive" />
        <img src={require('~images/products/QQL/Features/6. Seamless fee management 2.png').default} alt="Seamless Fee Management" className="margin-auto img-responsive" />
      </div>

<h5>Mobile Phone and Tablet Apps</h5>
    <p>Parents, teachers and administrators can access our services through mobile phone and tablet apps, making it easy for everyone to stay informed. You can manage sign-in/out through our logbook tablet, ensuring accurate attendance records, while our mobile app lets parents stay up-to-date with their child's progress. The teacher app provides access to attendance reports, incident logs, student records and more, ensuring teachers have everything they need at their fingertips.
</p>
<div className="productFeatures flex-full">
<div class="productApp">
<img src={require('~images/products/QQL/Features/App/app-student.png').default} alt="Student App" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Features/App/app-school.png').default} alt="School App" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Features/App/app-teacher.png').default} alt="Teacher App" className="margin-auto img-responsive" />
        <img src={require('~images/products/QQL/Features/App/app-logbook.png').default} alt="Logbook App" className="margin-auto img-responsive" />
</div>
<p><br/>Find out more about <a href="https://www.qoqolo.com/features/qoqolo-apps" title="Find out more about Qoqolo Apps" target="_blank">Qoqolo Apps</a></p>
<div class="supportedDevices">
<img src={require('~images/products/QQL/Features/App/supported_browser.jpg').default} alt="Supported Browsers" className="margin-auto img-responsive" />
 <img src={require('~images/products/QQL/Features/App/ios.png').default} alt="App Store" className="margin-auto img-responsive" />
 <img src={require('~images/products/QQL/Features/App/play.png').default} alt="Play Store" className="margin-auto img-responsive" />
</div>
      </div>


      <h5>Experience the Qoqolo Advantage</h5>
    <p>Qoqolo's School Management System is designed to cater to all your school's needs. With multilingual support, intuitive interfaces, and mobile apps for parents and teachers, we provide the ultimate solution for your school's digital transformation. Try Qoqolo today and experience the ease and convenience of our smart companion for your preschool!
</p>
<br/>
<h5>Our Customers</h5>
<div className="product-customers">
<img src={require('~images/products/QQL/Customers/1.star.jpg').default} alt="Star Learners" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/2.whitelodge.jpg').default} alt="Whitelodge" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/3.invictus.jpg').default} alt="Invictus International School" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/4.methodist.jpg').default} alt="Methodist Preschool" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/5.living.jpg').default} alt="Living Sanctuary" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/6.britishcouncil.jpg').default} alt="British Council" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/7.swallows.jpg').default} alt="Swallows" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/8.jamiyah.jpg').default} alt="Jamiyah" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/9.fareastern.jpg').default} alt="Far Eastern" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/10.afterschool.jpg').default} alt="Afterschool" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/11. wecareAMKmethodist.jpg').default} alt="We Care AMK Methodist" className="margin-auto img-responsive" />

<img src={require('~images/products/QQL/Customers/12.bedokmethodist.jpg').default} alt="Bedok Methodist" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/13. MWSdjoy.jpg').default} alt="MWSdjoy" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/14.pentecost.jpg').default} alt="Pentecost" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/15.sunshinekids.jpg').default} alt="Sunshine Kids" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/16.sweetlands.jpg').default} alt="Sweetlands" className="margin-auto img-responsive" />

<img src={require('~images/products/QQL/Customers/children-home.png').default} alt="Children Home" className="margin-auto img-responsive" />

<img src={require('~images/products/QQL/Customers/ichiban-monetessori.png').default} alt="Ichiban Monetessori" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/lil-mighty-me.png').default} alt="Lil Mighty Me" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/lil-playskool-bee.png').default} alt="Lil Playskool Bee" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/mamareoaks.png').default} alt="Mamre Oaks" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/SAAC.png').default} alt="SAAC" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/SAMC.png').default} alt="SAMC" className="margin-auto img-responsive" />
<img src={require('~images/products/QQL/Customers/super-genius.png').default} alt="Super Genius" className="margin-auto img-responsive" />

</div>
<br/>
<p className="text-center">
  <a href="https://www.qoqolo.com" title="Visit Qoqolo">
    <img src={require("~images/products/qoqolo.png").default} alt="Qoqolo" title="Qoqolo" className="margin-auto img-responsive block" />
        Visit Qoqolo
    </a>
      </p>
<h5 className="contactus-info">Contact Us</h5>
      <p>For more information or sales enquiries, email us at <a href="mailto:biz@commontown.com" alt="" >biz@commontown.com</a> or call us at <a href="tel:6848-8900" alt="">6848-8900</a>.</p>
      {/* <p>To learn more, visit <button type="button" className="type-link" onClick={() => window.open('https://www.qoqolo.com')} onKeyDown={() => window.open('https://www.qoqolo.com')} >www.qoqolo.com</button> or <button type="button" className="type-link" onClick={() => window.open('https://www.facebook.com/CTqoqolo')} onKeyDown={() => window.open('https://www.facebook.com/CTqoqolo')}  >Qoqolo’s Facebook</button>.</p>

      <p>For sales enquiries, email us at <a href="biz@commontown.com" alt="">biz@commontown.com</a> or call us at <a href="tel:68488900" alt="">6848 8900</a>.</p> */}
    </Fragment>
  )


  return <Sub title="Qoqolo" crumbs={crumbs} content={content} aside={aside} />
}

export default QoqoloPage
